// src/Gallery.js
import React, { useCallback, useRef } from 'react';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';

const imageData = [
    { src: require('./images/Screenshot 2024-06-26 at 8.37.08 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.37.08 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.37.08 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.37.19 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.37.19 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.37.19 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.37.28 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.37.28 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.37.28 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.37.36 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.37.36 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.37.36 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.37.45 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.37.45 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.37.45 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.37.53 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.37.53 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.37.53 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.38.01 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.38.01 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.38.01 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.38.09 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.38.09 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.38.09 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.38.17 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.38.17 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.38.17 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.38.26 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.38.26 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.38.26 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.38.38 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.38.38 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.38.38 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.38.46 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.38.46 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.38.46 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.38.53 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.38.53 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.38.53 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.39.01 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.39.01 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.39.01 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.39.09 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.39.09 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.39.09 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.39.15 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.39.15 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.39.15 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.39.27 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.39.27 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.39.27 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.39.35 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.39.35 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.39.35 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.39.43 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.39.43 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.39.43 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.39.51 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.39.51 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.39.51 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.39.59 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.39.59 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.39.59 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.40.06 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.40.06 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.40.06 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.40.19 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.40.19 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.40.19 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.40.27 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.40.27 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.40.27 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.40.35 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.40.35 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.40.35 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.40.50 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.40.50 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.40.50 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.41.00 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.41.00 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.41.00 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.41.41 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.41.41 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.41.41 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.43.17 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.43.17 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.43.17 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.43.25 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.43.25 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.43.25 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.43.31 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.43.31 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.43.31 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.43.38 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.43.38 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.43.38 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.43.44 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.43.44 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.43.44 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.43.52 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.43.52 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.43.52 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.44.00 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.44.00 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.44.00 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.44.08 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.44.08 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.44.08 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.44.15 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.44.15 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.44.15 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.44.23 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.44.23 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.44.23 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.44.31 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.44.31 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.44.31 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.44.37 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.44.37 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.44.37 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.44.49 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.44.49 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.44.49 AM.png</h4><p>Description</p>' },
    { src: require('./images/Screenshot 2024-06-26 at 8.44.56 AM.png'), thumb: require('./images/Screenshot 2024-06-26 at 8.44.56 AM_thumb.png'), subHtml: '<h4>Screenshot 2024-06-26 at 8.44.56 AM.png</h4><p>Description</p>' }
];

const Gallery = () => {
    const lightGalleryRef = useRef(null);
    const onInit = useCallback((detail) => {
        if (lightGalleryRef.current) {
            lightGalleryRef.current = detail.instance;
        }
    }, []);

    return (
        <LightGallery
            onInit={onInit}
            speed={500}
            plugins={[lgThumbnail, lgZoom]}
            mode="lg-fade"
        >
            {imageData.map((item, index) => (
                <a key={index} data-src={item.src} data-sub-html={item.subHtml}>
                    <img src={item.thumb} alt={`Thumbnail ${index + 1}`} />
                </a>
            ))}
        </LightGallery>
    );
};

export default Gallery;
